<template>
  <div class="container">
    <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <input type="file" accept=".csv" @change="handleFileUpload( $event )" img src="../../assets/SoftwareClinicIcon/Office-excel-xls-icon.png" height="30" weight="30"/>
              ต้องเป็นไฟล์นามสกุล .CSV UTF-8
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-btn outlined color="primary" style="text-transform: capitalize" @click="exportCSV">Download Template</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-btn outlined color="primary" style="text-transform: capitalize" @click="submitUpdates">Send SMS</v-btn>
            </v-col>
          </v-row>         
        </v-card-text>
      </v-card>
    <v-divider class="mx-4" inset vertical></v-divider>
    <v-spacer></v-spacer>
    <table v-if="parsed" style="width: 100%;">
      <thead>
          <tr>
              <th v-for="(header, key) in content.meta.fields"
                  v-bind:key="'header-'+key">
                  {{ header }}
              </th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(row, rowKey) in content.data"
              v-bind:key="'row-'+rowKey">
                  <td v-for="(column, columnKey) in content.meta.fields"
                      v-bind:key="'row-'+rowKey+'-column-'+columnKey">
                          <input v-model="content.data[rowKey][column]"/>
                  </td>
          </tr>
      </tbody>
    </table>
  </div>
</template>
<script>

import Papa from 'papaparse'
import Base64Binary from 'base64-arraybuffer'
export default {
  data () {
    return {
      file: '',
      content: [],
      parsed: false
    }
  },
  methods: {
    handleFileUpload (event) {
      this.content = []
      this.file = event.target.files[0]
      this.parseFile()
    },
    parseFile () {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results
          this.parsed = true
          console.log(this.content)
        }.bind(this)
      })
    },
    // submitUpdates () {
    //   axios.post('/preview-file-changes',
    //   this.content.data
    //   ).then(function () {
    //     console.log('SUCCESS!!')
    //   })
    //   .catch(function () {
    //     console.log('FAILURE!!')
    //   })
    // },
    exportCSV () {
      this.axios.post(process.env.VUE_APP_API + '/bulk_sms/ExportCSV', {
      }).then(response => {
        if (response.data.isSuccess) {
          const url = window.URL.createObjectURL(new Blob([Base64Binary.decode(response.data.excel_base64)], {type: 'text/csv; charset=utf-8'}))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'BulkSMSTemplate.csv')
          document.body.appendChild(link)
          link.click()
        } else {
          this.$swal({type: 'error', title: response.data.reasonText})
        }
      })
      .catch(e => {
        this.$swal({type: 'error', title: e.message})
      })
    },
    submitUpdates () {
      var isError = false
      var phoneList = ''
      this.content.data.forEach((item, index) => {
        console.log(item['Phone'])
        if (item['Phone'].length !== 10) {
          isError = true
          phoneList += item['Phone'] + ','
        }
      })
      if (phoneList.length > 0) {
        this.$swal({type: 'error', title: 'เบอร์โทรศัพท์ ' + phoneList + ' ไม่ถูกต้อง'})
      } else {
        this.axios.post(process.env.VUE_APP_API + '/bulk_sms/sendSMS', {
          data: this.content.data
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              // this.resetData()
              // this.dialog.dialogPrint = false
              // this.vDataTable.editedItem.register_skey = response.data.data.register_skey
              // this.vDataTable.editedIndex = response.data.data.register_skey
              // this.dialog.dialogProgress = false
              // this.uploadFile()
              // his.retrieveRegisterPartner() // this.uploadFile()
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
              this.dialog.dialogProgress = false
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
            this.dialog.dialogProgress = false
          })
      }
    },
  }
}
</script>
<style scoped>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>